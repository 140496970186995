<template>
  <div class="messages">
    <div class="top">
      <div class="title-box">
        消息
      </div>
      <div class="btn-box">
        <el-button
          type="primary"
          size="mini"
          @click="goback"
        >
          返回
        </el-button>
      </div>
    </div>
    <div class="content" @scroll="scroll_content">
      <el-collapse >
        <el-collapse-item v-for="(item,index) in listData" :key="index">
          <template slot="title">
            <div class="item-title">
              <img v-show="!item.is_read" src="@/assets/img/news.png" alt="" />
              <img v-show="item.is_read" src="@/assets/img/nonews.png" alt="" />
              <span>{{item.title}}</span>
              <span>{{item.push_time}}</span>
            </div>
          </template>
          <div class="item-content">
            <div v-if="item.message_type == 1">
              <p class="tip" style="color:red;">告警</p>
              <p>告警类型:{{item.title}}</p>
              <p>机号: {{item.content.instrument_id}} &nbsp; {{item.content.position}}</p>
              <p>医院: {{item.content.hospital}}</p>
              <p>告警时间: {{item.content.warning_time}}</p>
            </div>
            <div v-if="item.message_type == 2">
              <p class="tip" style="color:#E6A23C;">{{item.title}}</p>
              <p>机号: {{item.content.instrument_id}} &nbsp; {{item.content.position}}</p>
              <p>医院: {{item.content.hospital}}</p>
              <p>下次保养时间: {{item.content.next_date}}</p>
            </div>
            <div v-if="item.message_type == 3">
              <p class="tip"  style="color: red;">{{item.content.rule}}:失控</p>
              <p class="result-box">结果: <span>{{item.content.result}}</span><span>{{item.content.level}}</span></p>
              <p>项目: {{item.content.testing}}</p>
              <p>机号: {{item.content.instrument_id}} &nbsp; {{item.content.position}}</p>
              <p>型号: {{item.content.platform}}</p>
              <p>医院: {{item.content.hospital}}</p>
            </div>
          </div>
        
        </el-collapse-item>
   
      </el-collapse>
    </div>
  </div>
</template>

<script>
import api from '@/api/message.js'
export default {
  data() {
    return {
      pageSize: 20,
		  pageNum: 0,
      listData: [],
      hasNextPage: true,
      status: 'more',
    }
  },
  mounted() {
    this.get_push_messages();
  },
  destroyed() {
    let tempList = this.listData.filter(e => e.is_read == false)
		if(tempList.length==0){
			this.check_message_queueFun();
		}else{
			let pk_list = tempList.map(e => e.push_id)
			api.change_isread({pk_list}).then(res => {
				this.check_message_queueFun();
			})
		}
	},
  methods: {
    
    // 获取列表
		get_push_messages() {
      if (this.status === "loading") {
			  return false;
			}else {
        this.pageNum++;
      }
			this.status = "loading";
      const total = this.pageNum * this.pageSize;
      let param = {
				  page: this.pageNum,
				  size: this.pageSize,
				};
      api.push_messages(param).then(res => {
        if(res.code === 200) {
          let data = res.data.data;
          if (data.length > 0) {
					    this.status = "more";
					    this.listData = this.listData.concat(data);
					    this.hasNextPage = res.data.count > total;
					} else {
					  this.pageNum--;
					  if (this.pageNum < 1) {
					    this.pageNum = 1;
					  }
					  this.status = "noMore";
					}
					if (data.length < this.pageSize) {
					  this.status = "noMore";
					}
        }
      })
    },
    // 返回上一页
    goback() {
      // this.$router.push({ path: '/dataBoard',})
      window.history.back()
    },
    // 刷新已读消息状态
		update_chang_isread(pk,is_read){
			api.chang_isread({pk,is_read}).then(res => {
				
			})
		},
    // 消息推送星标
    check_message_queueFun(){
      api.check_message_queue().then(res=>{
				this.$store.commit("domessage_count", res.data.message_count);
			})
    },
    //触底刷新机制
    scroll_content(event) {
      let el=event.target;
    
      if(el.scrollTop+el.clientHeight+10>=el.scrollHeight){
        if (this.hasNextPage) {
          this.get_push_messages();
        } else {
          this.status = "noMore";
        }
        // this.get_push_messages();

      }
    },
  }
}
</script>

<style lang="scss" scoped>
.messages {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: url(../../assets/img/bg.png);
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: px(20) 5%;
    .title-box {
      font-weight: bold;
      color: #111111;
      font-size: $title-size;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
   
  }
  .content {
    width: 90%;
    margin-left: 5%;
    height: calc(100vh - px(120));
    overflow: auto;
  }
}
.item-title {
  padding-left: px(20);
  display: flex;
  align-items: center;
  font-size: px(16);
  img {
    width: px(30);
    height: px(30);
  }
  span {
    margin-left: px(10);
  }
}
.item-content {
  padding-left: px(20);
  .tip {
    font-size: px(18);
    font-weight: bold;
  }
  p {
    font-size: px(16);
  }
  .result-box {
    &> span {
      font-size: px(18);
      font-weight: bold;
    }
    span:nth-child(2) {
      margin-left: px(10);
    }

  }
}
</style>